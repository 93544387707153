<template>
  <div class="hero-banner relative overflow-hidden bg-charcoal-100">
    <!-- Text Protect -->
    <div class="absolute top-0 z-10 h-1/3 w-screen bg-gradient-to-b from-charcoal-100 mix-blend-multiply" />

    <!-- Image Gallery -->
    <div
      v-if="slice.variation === 'default'"
      class="swiper"
    >
      <div class="swiper-wrapper max-w-screen">
        <div
          v-for="(item, index) in slice.primary.gallery"
          :key="index"
          class="swiper-slide"
          :class="`slide--${index}`"
        >
          <PrismicImage
            :field="item.image"
            class="h-[50vh] w-full object-cover sm:h-auto"
          />
        </div>
      </div>
    </div>

    <div
      v-if="slice.variation === 'video'"
      class="relative size-full"
    >
      <!-- VIMEO -->
      <iframe
        v-if="slice.primary.source === 'Vimeo'"
        class="absolute left-0 top-0 h-[56.25vw] w-full"
        :src="`https://player.vimeo.com/video/${slice.primary.video_id}?byline=0&amp;muted=1&amp;autoplay=1&amp;loop=1&amp;autopause=0&amp;controls=0&amp;background=1`"
        frameborder="0"
        allow="autoplay"
        allowfullscreen
        data-ready="true"
      />

      <!-- YOUTUBE -->
      <iframe
        v-if="slice.primary.source === 'Youtube'"
        class="absolute left-0 top-0 h-[56.25vw] w-full"
        :src="`https://www.youtube.com/embed/${slice.primary.video_id}`"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Content } from "@prismicio/client";

import Swiper from "swiper";
import { Autoplay, EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";

// The array passed to `getSliceComponentProps` is purely optional.
// Consider it as a visual hint for you when templating your slice.
const props = defineProps(
  getSliceComponentProps<Content.HeroHeaderSlice>([
    "slice",
    "index",
    "slices",
    "context",
  ]),
);

onMounted(() => {
  // init Swiper:

  const swiperCarousel = new Swiper(".swiper", {
    speed: 2000,
    loop: true,
    modules: [Autoplay, EffectFade],
    effect: "fade",
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
  });
},
);
</script>

<style>
  .hero-banner:has( + .ticker-tape) {
    max-height: 92vh;
  }

  .hero-banner:has( + .ticker-tape.big) {
    max-height: 85vh;
  }
</style>
